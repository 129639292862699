export default {
  assets_manager: {
    upload_errors: {
      extension_not_allowed: 'Only the mp3, mp4 ,png, jpg, xml, pdf, svg formats are allowed, this file will not be considered for upload',
      file_audio_is_invalid: 'Audio files must have the following name structure: "text_number_language_version" (e.g. gen_210_en_v1.mp3)',
      file_video_is_invalid: 'Image and video files must have the following name structure: "text_number_version" (e.g. cons_210_v1.jpg)',
      file_image_is_invalid: '$$assets_manager.upload_errors.file_video_is_invalid',
      file_already_exists: 'This file already exists in the system',
      invalid_language: 'Only files with the languages :languages are allowed',
      missing_version: ':type files must have the version (e.g. :example_v2.:ext)',
      file_application_is_invalid: 'File is not valid for the selected folder',
      generic: 'An unknown error occurred with this file: ',
    },
    upload_files: 'Upload files',
    validation_modal_with_error_title: 'Some of the files you are trying to upload have errors!',
    upload: 'Upload',
    files_list: 'Files list',
    browse: 'Browse',
    uploaded: 'Uploaded',
    destination_folder: 'Destination folder',
    translate_system: {
      add_new_language: 'Add new language',
      language: 'Language',
      active: 'Active',
      available_languages: 'Available languages',
      languages: 'Languages',
      no_languages_available: 'No languages available',
      deactivating_language_modal_title: 'You are deactivating a language.',
      deactivating_language_modal_body: 'When you turn off a language, all warnings in that language will be ignored.',
    },
    leaving_without_saving_modal_title: 'You\'re leaving without saving changes.',
    do_you_want_to_continue: 'Do you want to continue?',
    do_you_want_to_save: 'Do you want to save?',
    changes_will_be_lost: 'If you do not save the changes will be lost.',
    action_confirmation_required: 'Action confirmation required',
  },
  finance: {
    menubar: {
      transactions: 'Transactions',
      import_transactions: 'Import Transactions',
      statements: 'Statements',
      claims_batches: 'Claims Batches',
      invoices: 'Invoices',
      billing_reports: 'Billing Reports',
      blocklist: 'Blocklist Members',
      monthly_revenue: 'Revenue',
      transactions_pepm: 'PEPM Transactions',
      updates: 'Updates',
    },
    global: {
      export_csv: 'Export CSV',
      transactions: 'Transactions',
      statements: 'Account Statements',
      errors: {
        export_csv: 'Error exporting CSV',
        export_zip: 'Error exporting zip',
        export_837_file_not_available: 'This claims batch file is not available for download',
        export_837_file_generic: 'Something went wrong, please try again later',
      },
    },
    financial_list: {
      actions: 'Actions',
      icons: {
        edit: 'Edit',
        details: 'Details',
        export_details: 'Details (.csv)',
        export_837_file: '837 File (.txt)',
        export_837_file_sample: 'Sample 837 File (.txt)',
        download: 'Download',
      },
    },
    billing_configurations: {
      template_input_label: 'Billing configuration template',
      template_input_option_default: 'No Template',
      template_button_apply: 'Apply template',
      template_error: 'Error applying template',
      template_mismatch_rules: 'The number of rules between the original billing configuration and what is on the form is different',
      template_mismatch_revenue_shares: 'The number of revenue share configurations between the original billing '
        + 'configuration and what is on the form is different',
      template_mismatch_statement_configs: 'The number of statement configurations between the original billing '
        + 'configuration and what is on the form is different',
      template_mismatch_aso_groups: 'The number of ASO Groups between the original billing configuration and what is on the form is different',
      template_past_price_escalator_start: 'The Price Escalator Start Date of the original billing configuration is in the past and was not copied',
      history_section_name: 'History',
      new_billing_model_section: 'New Billing Configuration',
      new_billing_model_section_save_btn: 'Save Configurations',
      set_new_billing_status_btn: 'Set as New',
      billing_model_rules_section: 'Billing model & Rules',
      fees_conditions_section: 'Fees & Conditions',
      price_escalator_section: 'Price Escalator',
      revenue_share_section: 'Revenue Share',
      implementation_credit_section: 'Implementation Credit',
      statements_configurations_section: 'Statements Configurations',
      fields: {
        billing_model: 'Billing Model',
        aso_groups: 'Applicable ASO Groups',
        aso_groups_description: 'Select applicable ASO Groups, if needed',
        rules: 'Rules',
        units: 'Applicable units',
        with_predict: 'Predict',
        with_cbt: 'CBT',
        with_ect: 'ECT',
        service_type: 'Applicable member service type',
        service_type_description: 'Select applicable member service type, if needed',
        status: 'Status',
        start_date: 'Start date (UTC)',
        end_date: 'End date (UTC)',
        end_date_save_btn: 'Save',
        end_date_invalid_value: 'You need to select a valid date',
        end_date_save_success: 'End Date saved successfully',
        end_date_save_error: 'Error to save End Date',
        enrollment_fee: 'Enrollment fee',
        session_fee: 'Session fee',
        full_fee: 'Full fee',
        session_cap: 'Session cap',
        last_x_days: 'Last X days',
        pepm_fee: 'PEPM fee',
        pepm_fee_description: 'Monthly Fee (PEPM) Value ($)',
        pepm_eligible_population: 'Prevention eligible population',
        pepm_eligible_population_description: 'Eligible population to be considered for the monthly fee',
        not_chargeable_health_plans: 'Not chargeable Health Plans',
        not_chargeable_health_plans_description: 'Select the health plans that will generate 0$ transactions.',
        not_chargeable_health_plans_placeholder: 'Add Health Plans',
        not_chargeable_health_plans_tag_placeholder: 'Press enter to add',
        not_chargeable_health_plans_help: 'Enter the plan description as is on the client eligibility file. Transactions '
          + 'for members with these plans will be generated as 0$.',
        currency: 'Billing currency',
        pepm_fee_calculation: 'PEPM fee calculation',
        move_discount_label: 'Move discount (%)',
        move_discount_input_helper_text: 'Configure the discount (%) applied to the Move yearly fee if the member '
          + 'completed other program first in the same OB year',
        created_at: 'Created at',
        created_by: 'Created by',
        price_escalator_start_date: 'Price Escalator Start Date',
        price_escalator_start_date_description: 'Date for the escalated price to start being applied',
        price_escalator_interval: 'Price Escalator Cadence',
        price_escalator_interval_description: 'Cadence to apply the price escalator',
        price_escalator_percentage: 'Price Escalator (%)',
        price_escalator_percentage_description: 'Percentage to apply to the price escalator (%)',
        price_escalator_original_billing_config: 'Escalated from billing configuration',
        pepm_employee_count: 'Employee Count',
        pepm_employee_count_description: 'Pre-determined Employee Count to be considered for the PEPM fee',
        three_sessions_fee: '3 sessions fee',
        nine_sessions_fee: '9 sessions fee',
        pain_score_reduction_fee: 'Pain score reduction fee',
      },
      rules: {
        placeholder: 'Select at least one rule',
        no_rules: 'No rules configured',
        notes: 'Notes',
        inactive: 'No longer active',
        replaced_by: 'Replaced by rule',
        rule_id: 'ID',
        rule_description: 'Description',
      },
      history: {
        id: 'ID',
        start_date: 'Start Date',
        end_date: 'End Date',
        billing_model: 'Billing Model',
        units: 'Applicable units',
        service_type: 'Service type',
        aso_groups: 'ASO Groups',
        predict: 'Predict billing',
        cbt: 'CBT billing',
        ect: 'ECT billing',
        status: 'Status',
        actions: 'Actions',
        view_details: 'View Details',
        delete: 'Delete',
        update: 'Update',
        delete_modal_confirmation: 'Are you sure you want to delete this billing configuration?',
        update_status_modal_confirmation: 'Are you sure you want to update the status of this billing configuration?',
        not_chargeable_health_plans_save_btn: 'Save',
        not_chargeable_health_plans_confirm_add: 'add',
        not_chargeable_health_plans_confirm_remove: 'remove',
        not_chargeable_health_plans_save_confirmation: 'Confirm changes to non chargeable health plans',
        not_chargeable_health_plans_updated: 'Not chargeable health plans updated',
        not_chargeable_health_plans_update_error: 'Error updating not chargeable health plans',
        yes: 'Yes',
        billing_config_updated: 'Billing configuration updated',
        billing_config_update_error: 'Error updating billing configuration',
        copy_for_edition: 'Copy for edition',
      },
      revenue_share: {
        partnership: 'Partnership',
        partnership_description: 'Select the corresponding Partnership. If applicable.',
        sharing: {
          title: 'Revenue Share',
          yes: 'Yes',
          no: 'No',
        },
        add_another: 'To add another revenue share configuration use the fields below',
        calculation: 'Calculation',
        calculation_description: 'Select how the revenue share is calculated',
        billing_mode: 'Billing mode',
        billing_mode_description: 'Select how the revenue share is applied for Direct Invoice statements (Claims will always be Vendor Bill)',
        treatment: 'Treatment',
        treatment_description: 'Select the revenue share applicable treatment',
        calculation_share_percentage: 'Rev Share percentage',
        calculation_share_percentage_description: 'Percentage to apply for the revenue share',
        calculation_share_flat_fee: 'Rev Share flat fee',
        calculation_share_flat_fee_description: 'Flat fee to apply for the revenue share',
        flat_fee_percentage: 'Percentage / Flat fee per enrollment',
        empty_information: 'No Revenue Share configured, use the fields below',
        empty_information_history: 'No Revenue Share configured',
      },
      implementation_credit: {
        payable_to: 'Payable to',
        payable_to_description: 'Select the corresponding entity if applicable',
        billing_mode: 'Billing Mode',
        billing_mode_description: 'Select how the implementation credit is applied',
      },
      statements_configurations: {
        billing_type: 'Billing Type',
        billing_period: 'Billing Period',
        billing_provider: 'Sword Provider',
        payer: 'Payer',
        generate_claims_batches: 'Generate Claims Batches',
        generate_837_file: 'Generate 837 file',
        generate_external_claims_report: 'Generate external claims report',
        clearing_house: 'Clearing house',
        actions: 'Actions',
        required_one_error: 'Please add a statement configuration.',
        empty_information: 'No statement configuration added, use the fields below',
        add_another: 'To add another statement configuration use the fields below',
        generate_claims_batches_confirm_enable: 'Are you sure you want to enable the generation of claims batches?',
        generate_claims_batches_confirm_disable: 'Are you sure you want to disable the generation of claims batches?',
        generate_claims_batches_cannot_disable: 'Please first disable the generation of 837 file',
        generate_claims_batches_cannot_enable_empty_clearing_house: 'Please, configure a clearing house first',
        generate_837_file_confirm_enable: 'Are you sure you want to enable the generation of the 837 file?',
        generate_837_file_confirm_disable: 'Are you sure you want to disable the generation of the 837 file?',
        generate_external_claims_report_confirm_enable: 'Are you sure you want to enable the generation of the external claims report?',
        generate_external_claims_report_confirm_disable: 'Are you sure you want to disable the generation of the external claims report?',
        clearing_house_mandatory: 'Clearing house is mandatory when "Generate Claims Batches" is enabled',
        clearing_house_confirm_change: 'Are you sure you want to change the clearing house for this statement configuration?',
        updated: 'Statement configuration updated',
        update_failed: 'Error updating statement configuration',
      },
      confirm_creation_popup_missing_units: {
        title: 'Warning',
        sentence1Middle: 'won’t be configured starting from',
        sentence2Begin: 'Please add this unit or create a new billing configuration with the same start date',
        sentence2End: 'so that transactions can be generated.',
        ok: 'Save',
      },
      confirm_creation_popup_units_competing_configs: {
        title: 'Warning',
        sentence1: 'Please review the active billing configurations regarding the member service type.',
        sentence2Begin: 'More than one competing configuration for the same unit(s)',
        sentence2End: 'will be available.',
        ok: 'Save',
      },
    },
    client_configs: {
      title: 'Client Billing Configurations',
      save: 'Save client configurations',
      billing_date_label: 'Follow billing model & rules according to:',
      billing_date_help: 'Select which model applies to billing configuration transitions',
      bill_through_a_different_client_label: 'Bill through a different client',
      bill_through_client_id_label: 'Bill through client ID:',
      invoices_group_by_client_label: 'Invoices - Group by client',
      hold_invoices_label: 'Hold invoices',
      purchase_order_number_label: 'PO number:',
      purchase_order_number_description: 'Purchase order of the client',
      ship_to_addressee_label: 'Ship To - Addressee:',
      ship_to_address_label: 'Ship To - Address:',
      ship_to_city_label: 'Ship To - City:',
      ship_to_state_label: 'Ship To - State:',
      ship_to_state_placeholder: '2 letter state code',
      ship_to_zipcode_label: 'Ship To - Zip Code:',
      ship_to_zipcode_placeholder: '9 digit postal code',
      ship_to_country_label: 'Ship To - Country:',
      ship_to_country_placeholder: '2 letter country code',
      bill_to_addressee_label: 'Bill To - Addressee:',
      bill_to_address_label: 'Bill To - Address:',
      bill_to_city_label: 'Bill To - City:',
      bill_to_state_label: 'Bill To - State:',
      bill_to_state_placeholder: '2 letter state code',
      bill_to_zipcode_label: 'Bill To - Zip Code:',
      bill_to_zipcode_placeholder: '9 digit postal code',
      bill_to_country_label: 'Bill To - Country:',
      bill_to_country_placeholder: '2 letter country code',
      customer_billing_name: 'Customer billing name',
      email_recipients: {
        label: 'Email of Recipients',
        placeholder: 'Add Email',
        invalid_email_message: 'Invalid Email',
        duplicate_email_message: 'Duplicate Email',
      },
      terms: {
        label: 'Terms:',
        help: "Select the standard account terms for this customer's invoices",
        options: {
          empty_value: '',
          net_15: 'Net 15',
          net_20: 'Net 20',
          net_30: 'Net 30',
          net_45: 'Net 45',
          net_60: 'Net 60',
          net_90: 'Net 90',
        },
      },
      billing_date_options: {
        event_date: 'Event date',
        onboarding_date: 'Onboarding date',
        custom_transition_unbundled: 'Custom transition - unbundled',
        custom_transition_sword_to_bundled_sword_bloom: 'Custom transition - sword to bundled sword+bloom',
      },
      custom_transitions_table: {
        title: 'Custom transitions',
        col_transition_date: 'Transition date',
        col_transition_date_help: 'Select the date to consider as the threshold date for the parent patient OB date/anniversary',
        col_billing_config: 'Billing config to follow',
        col_billing_config_help: 'Select the billing config to follow if the parent patient OB date/anniversary is before the transition date',
        col_units: 'Applicable units',
        col_actions: 'Actions',
        no_items: 'No billing configurations added, use the fields below',
      },
      errors: {
        fetch_error: 'An error occurred fetching client configuration',
        save_error: 'An error occurred saving client configs',
      },
      success: {
        save_success: 'Client configuration saved successfully',
      },
    },
    transactions: {
      list_page_title: 'Transactions',
      transaction_id: 'ID',
      created_at: 'Created at',
      client_name: 'Client Name',
      program_id: 'Program ID',
      patient_name: 'Patient name',
      therapy: 'Therapy',
      transaction_type: 'Transaction Type',
      program_uuid: 'Program UUID',
      account_uuid: 'Account UUID',
      value: 'Value',
      unit: 'Unit',
      value_type: 'Entry type',
      event_date: 'Event Date',
      error_loading_client_list: 'There was a problem retrieving client list',
      missing_transactions: 'No results available',
      partner_name: 'Partner name',
      filters: {
        client_id: 'Client',
        created_at: 'Created At',
        default_client_filter: 'All (Default)',
        program_id: 'Program ID',
        program_uuid: 'Program UUID',
        account_uuid: 'Account UUID',
        search: 'Search',
        select_date: 'Click to choose the date range',
      },
      errors: {
        loading_filters: 'Something went wrong when trying to load filters',
      },
    },
    import_transactions: {
      page_title: 'Import Transactions',
      tab_manual: {
        title: 'Transactions',
        instructions: {
          line_1: 'Mandatory Fields:',
          line_2: 'SWORD Patient ID',
          line_3: 'Transaction type and transaction type reason (when applicable) must be one of the following:',
          line_4: 'Value with decimal places according to country currency, credit (-) or debit (+) and \'.\' as decimal',
          line_5: 'Description: free text with 256 chars max.',
          line_6: 'Event date (format ‘YYYY-MM-DD HH:MM:SS’, timezone UTC)',
          line_7: 'Billable date (format ‘YYYY-MM-DD HH:MM:SS’, timezone UTC)',
          line_8: 'Extra (optional) fields:',
          line_9: 'Original transaction ID — use this field if the goal is to link the transaction to import to an existing '
            + 'one (use cases: credit existing transactions)',
          type_reason_required: 'transaction type reason is required, between:',
          type_reason_optional: 'following transaction type reason is accepted but not required:',
        },
        status_table: {
          import_id: 'Import ID',
          created_at: 'Created At',
          updated_at: 'Updated At',
          filename: 'File Name',
          owner: 'Created By',
          current_status: 'Status',
          empty_import_list: 'There is no imported transaction',
          upload_details: 'Upload details',
          view_details: 'View Details',
          export: 'Export',
        },
      },
      tab_pepm: {
        title: 'PEPM Transactions',
        instructions: {
          line_1: 'Mandatory Fields:',
          line_2: 'Client ID',
          line_3: 'Billing Configuration ID',
          line_4: 'Quantity (should be negative to cancel existing transaction)',
          line_5: 'Unit Value',
          line_6: 'Transaction Date (format ‘YYYY-MM-DD HH:MM:SS’, timezone UTC)',
          line_7: 'Observations: free text with 255 chars max.',
          line_8: 'Extra (optional) fields:',
          line_9: 'Original PEPM Transaction ID — use this field if the goal is to cancel an existing PEPM transaction; '
            + '(Quantity * Unit Value) must be the opposite of the original transaction value',
        },
      },
      file_placeholder: 'Upload file...',
      drop_placeholder: 'Drop file here...',
      transaction_status_header: 'Last Imported Transactions',
      error_exporting: 'Error exporting',
      button: {
        upload: 'Upload',
        download_template: 'Download Template',
      },
      results: {
        error_invalid_file: 'There was a problem with transactions csv file.',
        upload_error: 'Unable to upload file',
        upload_success: 'File uploaded with success.',
        error_no_file: 'No file selected.',
        error_not_csv: 'Please select a CSV file type',
        error_size_limit: 'CSV file exceeds size limit, it should have 1MB or less.',
        error_loading_status_list: 'There was a problem retrieving import status list',
      },
    },
    import_transactions_details: {
      page_title: 'CSV Import Details',
      import_id: 'Import ID',
      created_at: 'Created at',
      file_name: 'File name',
      created_by: 'Created by',
      status: 'Status',
      back_list: 'Back to List',
      results: {
        error_loading_list: 'There was a problem retrieving details',
      },
      error_exporting: 'Error exporting',
      transaction_list: {
        page_title: 'Transactions',
        empty_list: 'There are no manual transactions',
        fields: {
          transaction_id: 'ID',
          client_name: 'Client Name',
          program_id: 'Program ID',
          patient_name: 'Patient Name',
          therapy: 'Therapy',
          transaction_type: 'Transaction type',
          transaction_type_reason: 'Reason',
          value: 'Value',
          entry_type: 'Entry type',
          event_date: 'Event date',
          billable_date: 'Billable date',
          original_transaction_id: 'Original Transaction ID',
          status: 'Status',
        },
        fields_pepm: {
          id: 'ID',
          client_id: 'Client ID',
          billing_configuration_id: 'Billing Config',
          quantity: 'Quantity',
          unit_value: 'Unit value',
          transaction_date: 'Transaction date',
          original_transaction_pepm_id: 'Original Transaction ID',
          status: 'Status',
          created_transaction_id: 'PEPM ID',
          created_transaction_value: 'Value',
          created_transaction_value_type: 'Entry type',
          tooltip_transaction_observations: 'Observations',
          tooltip_observations: 'Error',
        },
      },
    },
    statements: {
      page_title: 'Account Statements',
      global: {
        export_details: 'Details (.csv)',
        export_summary: 'Summary (.csv)',
        export_837_file: '837 File (.txt)',
        export_external_claims_report: 'External claims report (.csv)',
        export_details_zip: 'Details (.zip)',
        export_summary_zip: 'Summaries (.zip)',
        export_summary_merge_csv: 'Summary Merge (.csv)',
        export_statement_fees_vendor_bill: 'Vendor Bill (.csv)',
      },
      statements_table: {
        id: 'ID',
        client_name: 'Client Name',
        start_date: 'Start Date',
        end_date: 'End Date',
        start_end_date: 'Start/End Date',
        balance: 'Balance',
        created_at: 'Created At',
        billing_type: 'Billing Type',
        payer: 'Payer Name',
        billing_period: 'Billing Period',
        empty_statements_list: 'No results available',
      },
      details: {
        page_title: 'Account Statement Details',
        back_list: 'Back to Statements List',
        id: 'ID',
        client_name: 'Client Name',
        start_date: 'Start Date',
        end_date: 'End Date',
        billing_type: 'Billing Type',
        payer: 'Payer Name',
        billing_period: 'Billing Period',
        balance: 'Balance',
        revenue_share: 'Revenue Share',
        created_at: 'Created At',
      },
      errors: {
        fetch_list_error: 'Error when trying to fetch statements',
        fetch_details_error: 'Error when trying to fetch statement',
        loading_filters: 'Something went wrong when trying to load filters',
        export_external_claims_report_not_available: 'Export external claims report is not available for download',
        export_external_claims_report_generic: 'Something went wrong, please try again later',
      },
      filters: {
        client_id: 'Client',
        created_at: 'Created At',
        start_end_date: 'Start/End Date',
        default_client_filter: 'All (Default)',
        search: 'Search',
        select_date: 'Click to choose the date range',
      },
      summary: {
        title: 'Account Statement Summary',
        quantity: 'Quantity',
        unit: 'Unit',
        transaction_type: 'Transaction Type',
        unit_value: 'Unit Value',
        amount: 'Amount',
        total_amount: 'TOTAL AMOUNT',
        gross_amount: 'GROSS AMOUNT',
        empty: 'No results available',
      },
      tabs: {
        account_statements: 'Account Statements',
        monthly_account_statements: 'Monthly Account Statement Summary',
      },
      statement_summary_merge_list: {
        id: 'ID',
        month: 'Month',
        created_at: 'Created at',
        action_download: 'Download',
        empty_table: 'No results available',
        fetch_list_error: 'Error when trying to fetch statement summary merge',
        file_not_available: 'This statement summary merge file is not available for download',
        file_generic_error: 'Something went wrong, please try again later',
      },
      export: 'Export',
    },
    invoices: {
      list_page_title: 'Invoices',
      invoices_table: {
        invoice_id: 'Invoice ID',
        external_id: 'External ID',
        billable_client_id: 'Billable client ID',
        billable_client: 'Billable Client',
        start_date: 'Start Date',
        end_date: 'End Date',
        created_at: 'Created at',
        billing_type: 'Billing type',
        payer: 'Payer',
        sword_provider: 'Sword Provider',
        status: 'Status',
        empty_invoice_list: 'No results available',
      },
      reject_invoice: 'Reject Invoice',
      successfully_rejected_invoice: 'The invoice has been rejected',
      push_to_netsuite: 'Push to Netsuite',
      successfully_pushed_to_netsuite: 'The invoice(s) have been set to pending and will be synced to Netsuite',
      errors: {
        reject_invoice: 'Error rejecting invoice',
        pushing_to_netsuite: 'Error pushing to netsuite',
        pending_invoice_not_found: 'Pending invoice was not found',
        invoice_on_hold: 'This billable client has the invoices held for submission',
        no_invoices_pushed_to_netsuite: 'There are no invoices with a pending status to be sent',
      },
      details: {
        invoice_detail: 'Invoice Details',
        invoice_id: 'Invoice ID',
        external_id: 'External ID',
        billable_client_id: 'Billable Client ID',
        billable_client: 'Billable Client',
        start_date: 'Start Date',
        end_date: 'End Date',
        status: 'Status',
        created_at: 'Created at',
        billing_type: 'Billing type',
        payer: 'Payer',
        sword_provider: 'Sword Provider',
        department: 'Department',
        line_department: 'Line Department',
        po_number: 'PO Number',
        addressee: 'Addressee',
        address: 'Address',
        city: 'City',
        state: 'State',
        zip_code: 'Zip Code',
        country: 'Country',
        clients: {
          client_list: 'Clients',
          id: 'Client ID',
          name: 'Client Name',
          empty_invoice_list: 'No results available',
        },
        statements: {
          statements_list: 'Account Statements',
          id: 'Statement ID',
          client_name: 'Client Name',
          start_date: 'Start Date',
          end_date: 'End Date',
          balance: 'Balance',
          created_at: 'Created at',
          empty_invoice_list: 'No results available',
        },
        invoice_summary: {
          invoice_summary_list: 'Invoice Summary',
          client_id: 'Client ID',
          client_name: 'Client Name',
          transaction_type: 'Transaction Type',
          quantity: 'Quantity',
          unitary_value: 'Unitary Value',
          unit: 'Unit',
          line_description: 'Line Description',
          empty_invoice_list: 'No results available',
        },
      },
      filters: {
        billable_client: 'Billable Client',
        default_billable_client: 'All (Default)',
        status: 'Status',
        external_invoice_id: 'External Invoice ID',
        error_loading_filters: 'Something went wrong when trying to load filters',
      },
    },
    billing_reports: {
      page_title: 'Billing Reports',
      tabs: {
        reports: 'Generated Reports',
        configurations: 'Configurations',
      },
      configs: {
        table: {
          empty_table: 'No reports configured',
          client: 'Client',
          group: 'Group',
          report_type: 'Report Type',
          cadence: 'Cadence',
          start_date: 'Start Date',
          end_date: 'End Date',
          sword_provider: 'Sword Provider',
          payer: 'Payer ID',
          sftp_filepath: 'File bucket',
          actions: 'Actions',
        },
        notifications: {
          add_success: 'Billing report configuration created',
          add_error: 'Error creating billing report',
          delete_success: 'Billing report configuration removed',
          delete_error: 'Error deleting billing report',
          update_success: 'Billing report configuration updated',
          update_error: 'Error updating billing report',
        },
        modals: {
          delete_billing_confirmation: 'Are you sure you want to remove the billing report configuration?',
          delete_client_confirmation: 'A client was removed from the configuration, do you want to proceed?',
          delete_button: 'Remove',
          yes_button: 'Yes',
        },
      },
      reports_list: {
        empty_table: 'No billing reports',
        report_id: 'Report ID',
        report_type: 'Report Type',
        clients: 'Clients',
        start_date: 'Start Date',
        end_date: 'End Date',
        created_at: 'Created At',
        sword_provider: 'Sword Provider',
        payer: 'Payer',
        cadence: 'Cadence',
        actions: 'Actions',
        action_download: 'Download',
      },
    },
    claims_batches: {
      page_title: 'Claims Batches',
      claims_batches_table: {
        id: 'ID',
        total_balance: 'Total Balance',
        status: 'Status',
        statement_id: 'Statement ID',
        client_name: 'Client Name',
        payer_name: 'Payer Name',
        provider_name: 'Provider Name',
        created_at: 'Created At',
        empty_claims_batches_list: 'No results available',
      },
      details: {
        export_837_file: 'Export 837 File (.txt)',
        page_title: 'Claims Batch Details',
        back_list: 'Back to Claims Batches List',
        id: 'ID',
        client_name: 'Client Name',
        statement_id: 'Statement ID',
        total_balance: 'Total Balance',
        status: 'Status',
        payer_name: 'Payer Name',
        payer_id: 'Payer ID',
        place_of_service: 'POS',
        provider_name: 'Provider Name',
        provider_npi: 'Provider NPI',
        name: 'Batch Name',
        created_at: 'Created At',
        claims: {
          section_title: 'Claims',
          unique_key: 'Claim unique key',
          program_id: 'Program ID',
          member_full_name: 'Patient Name',
          value: 'Charged Amount',
          paid_value: 'Paid Amount',
          status: 'Status',
          status_date: 'Status Date',
          member_insurance_number: 'Member ID',
          subscriber_first_name: 'Subscriber First Name',
          subscriber_last_name: 'Subscriber Last Name',
          member_relationship: 'Relationship',
          program_uuid: 'Program UUID',
          account_uuid: 'Account UUID',
        },
        transactions_list_title: 'Transactions',
        transactions: {
          value: 'Charged Amount',
          paid_value: 'Paid Amount',
        },
        claim_status_notifications: {
          list_title: 'Claim status notifications',
          no_rows: 'No results available',
          status_date: 'Status date',
          processed_at: 'processed at',
          status: 'Status',
          old_status: 'old was',
          new_status: 'new is',
          notes: 'Notes',
        },
      },
      errors: {
        fetch_list_error: 'Error when trying to fetch claim batches',
        fetch_overview_error: 'Error when trying to fetch claim batches overview',
        fetch_details_error: 'Error when trying to fetch claims batch',
        fetch_claims_error: 'Error when trying to fetch claims',
        fetch_status_notifications_error: 'Error when trying to fetch list of status changes',
        export_837_file_not_available: 'This claims batch file is not available for download',
        export_837_file_generic: 'Something went wrong, please try again later',
      },
    },
    payers: {
      section_title: 'Payers',
      form: {
        system_payer_id: 'Payer',
        payer_name: 'Payer Name',
        payer_id: 'Payer ID',
        address: 'Payer address',
        address_line1: 'Address',
        address_city: 'City',
        address_state: 'State',
        address_state_help: '2 letter state code',
        address_postal_code: 'Postal Code',
        address_postal_code_help: '9 digit postal code',
        address_country_code: 'Country Code',
        address_country_code_help: '2 letter country code',
        place_of_service: 'Place of Service (POS)',
        filing: 'Filing',
        group_number: 'Group Number',
        enrollment_dos_range: 'Enrollment DOS Range',
        no_payers: 'No payers added',
        actions: 'Actions',
        delete: 'Delete',
        delete_confirm: 'Are you sure you want to delete this payer?',
        delete_success: 'Payer deleted successfully',
        delete_error: 'An error occurred deleting payer',
        add_modal_title: 'Add new payer',
        add_button: 'Add',
        add_success: 'New payer created successfully',
        add_error: 'An error occurred creating payer',
        edit_modal_title: 'Edit payer',
        edit_button: 'Edit',
        edit_success: 'Payer edited successfully',
        edit_error: 'An error occurred editing the payer',
      },
      place_of_service: {
        empty: '(empty)',
        telehealth: '02 - Telehealth Provided Other than in Patient\'s Home',
        telehealth_patient_home: '10 - Telehealth Provided in Patient\'s Home',
        office: '11 - Office (Amwell only)',
      },
      modifier: {
        empty: '(empty)',
        interactive_video_telecommunications_systems: 'GT',
        synchronous_telemedicine_service: '95',
        amwell_only: '32 (Amwell only)',
        async_telecommunications_systems: 'GQ',
        physical_therapy_plan_of_care: 'GP',
        go: 'GO',
        x2: 'X2',
      },
      filing: {
        empty: '(empty)',
        commercial_insurance: 'CI - Commercial Insurance Co.',
        blue_cross: 'BL - Blue Cross / Blue Shield',
      },
      rendering_provider: {
        empty: '(empty)',
        physical_therapist: 'PT (Name+NPI)',
      },
      group_number: {
        empty: '(empty)',
        group_policy_number: 'Group Policy number',
      },
      client_payer_configs: {
        transaction_type: 'Transaction Type',
        unit: 'Unit',
        service_type: 'Service Type',
        transaction_value: 'Transaction Value',
        applicable_to: ' Use/Applicable to',
        billing_configuration_id: 'Billing Config ID',
        cpt_code: 'CPT Code',
        modifier: 'Modifier',
        rendering_provider: 'Rendering Provider',
        actions: 'Actions',
        delete_modal_title: 'Delete payer configuration',
        delete_modal_message: 'Are you sure you want to delete this payer configuration?',
        delete_feedback_success: 'Payer configuration deleted successfully',
        delete_feedback_error: 'An error occurred deleting payer configuration',
      },
    },
    blocklist: {
      page_title: 'Finance Module - member blocklist',
      table: {
        client: 'Client',
        program_id: 'Program ID',
        program_uuid: 'Program UUID',
        account_uuid: 'Account UUID',
        email: 'Email',
        created_by: 'Created by',
        created_at: 'Created at',
        reason: 'Reason',
        empty_list: 'No results available',
      },
      add_patient_form: {
        field_client: 'Client',
        field_patient_identification: 'Patient identification',
        field_value: 'Identification value',
        field_reason: 'Reason',
        save_btn: 'Save',
        open_form_btn: 'Add patient',
        add_success: 'Added to the blocklist',
        add_error: 'Error adding to the blocklist',
      },
      error_loading: 'Error loading blocklist',
      error_loading_filters: 'Error loading client names',
      delete_btn: 'Delete',
      delete_success: 'Deleted element with success',
      delete_error: 'Error deleting element',
      delete_confirm: 'Are you sure you want to remove this entry from the blocklist?',
      delete_confirm_ok_btn: 'Remove',
    },
    monthly_revenue: {
      page_title: 'Revenue',
      table: {
        client: 'Client',
        month: 'Month',
        gross_amount: 'Gross Amount',
        revenue_share_amount: 'Revenue Share',
        net_amount: 'Monthly Revenue',
        empty_list: 'No results available',
      },
      details: {
        page_title: 'Revenue Details',
        client: 'Client',
        month: 'Month',
        gross_amount: 'Gross Amount',
        revenue_amount: 'Revenue Share',
        monthly_revenue: 'Monthly Revenue',
        back_to_list: 'Back to Revenue List',
      },
      summary: {
        title: 'Revenue Summary',
        quantity: 'Quantity',
        unit: 'Unit',
        transaction_type: 'Transaction Type',
        unit_value: 'Unitary Value',
        amount: 'Amount',
        total_amount: 'TOTAL AMOUNT',
        gross_amount: 'GROSS AMOUNT',
        empty: 'No results available',
      },
      errors: {
        fetch_details_error: 'Error when trying to fetch monthly revenue',
      },
      error_loading: 'Error loading monthly revenues',
      filters: {
        client_id: 'Client',
        year_month: 'Month',
        default_client_filter: 'All (Default)',
        search: 'Search',
      },
    },
    transactions_pepm: {
      list_page_title: 'PEPM fee transactions',
      transaction_id: 'ID',
      created_at: 'Created at',
      client_name: 'Client Name',
      program_id: 'Program ID',
      partner_name: 'Partner name',
      transaction_type: 'Transaction Type',
      value: 'Value',
      value_type: 'Entry type',
      missing_transactions: 'No results available',
      errors: {
        fetch_list: 'Something went wrong when trying to load PEPM Fee Transactions',
      },
    },
    updates: {
      page_title: 'Updates',
      page_helper_text_1: `
        Select the client and the start date since when the updates are needed.
        At least one of the checkboxes must be selected and the 2 checkboxes can also be selected simultaneously for both actions to be performed.
      `,
      page_helper_text_2: `
        Check 'Update insurance info' to update the insurance info on the transaction level for the selected client's
        transactions created since the selected start date.
      `,
      page_helper_text_3: `
        Check 'Re-generate Acc Statements' to create new Acc Statements for the selected client since the selected start date.
      `,
      page_detail_breadcrumb: 'Back to Updates',
      page_detail_title: 'Request Details',
      actions_label: {
        export: 'Export',
        export_transactions: 'Export Transactions',
        details: 'View Details',
      },
      popup_force_update: {
        title: 'Updates - Confirmation',
        btn_ok: 'No, update/re-generate only',
        btn_cancel: 'Yes, send anyway',
        message: `
          The selected client has a statement configuration where 'Generate 837 file' in ON.
          By default, a claims batch file will not be sent to Waystar. Do you want to send a file to Waystar anyway?
        `,
      },
      table: {
        request_id: 'Request ID',
        client_name: 'Client Name',
        date_from: 'Date (from)',
        created_at: 'Created At',
        created_by: 'Created By',
        completed_at: 'Completed At',
        status: 'Status',
        actions: 'Actions',
        empty_list: 'No results available',
        update_and_regenerate_statements: 'Update insurance info / Re-generate statements',
      },
      errors: {
        fetch_details_error: 'Error when trying to fetch updates',
        fetch_request_update_error: 'Error when trying to fetch update insurance info',
        fetch_updates_loading_error: 'Error loading updates',
        fetch_clients_loading_error: 'Error loading clients',
      },
      input: {
        client_id: 'Client',
        statement_start_date_from: 'Start date - from',
        statement_start_date_from_placeholder: 'Click to choose the date',
        update: 'Update insurance info',
        regenerate_statements: 'Re-generate Acc Statements',
        submit: 'Update',
      },
    },
    system_payers: {
      section_title: 'Payers',
      form: {
        name: 'Name',
        visible_name: 'Visible Name',
        rte_payer_id: 'RTE Payer ID - Availity',
        rte_payer_waystar_id: 'RTE Payer ID - Waystar',
        rte_payer_change_healthcare_id: 'RTE Payer ID - Change Healthcare',
        rte_payer_mgb_id: 'RTE Payer ID - MGB',
        claims_payer_id: 'Claims Payer ID',
        full_address: 'Full address',
        address_line1: 'Address',
        address_city: 'City',
        address_state: 'State',
        address_state_help: '2 letter state code',
        address_postal_code: 'Postal Code',
        address_postal_code_help: '9 digit postal code',
        address_country_code: 'Country Code',
        address_country_code_help: '2 letter country code',
        no_payers: 'No payers added',
        actions: 'Actions',
        delete: 'Delete',
        delete_confirm: 'Are you sure you want to delete this payer?',
        delete_success: 'Payer deleted successfully',
        delete_error: 'An error occurred deleting payer',
        add_modal_title: 'Add new payer',
        add_button: 'Add',
        add_success: 'New payer created successfully',
        add_error: 'An error occurred creating payer',
        edit_modal_title: 'Edit payer',
        edit_button: 'Edit',
        edit_success: 'Payer edited successfully',
        edit_error: 'An error occurred editing the payer',
        delete_button: 'Delete',
        delete_modal_title: 'Confirmation',
        delete_modal_text: 'Are you sure you want to delete this Payer?',
      },
    },
  },
  commissions: {
    sidenav: {
      payouts: 'Payouts',
      configurations: 'Configurations',
    },
    statements: {
      nav_title: 'Statements',
      btn_generate: 'Generate Statements',
      btn_export: 'Export',
      btn_export_summaries: 'Payout Summaries (.zip)',
      btn_export_summary_merge: 'Payout Summary merge (.csv)',
      btn_export_details: 'Payout Details (.zip)',
      table: {
        id: 'ID',
        rvp_name: 'RVP name',
        total_balance: 'Total Balance',
        start_date: 'Start Date',
        end_date: 'End Date',
        created_at: 'Created At',
        actions: 'Actions',
        empty_list: 'No results available',
      },
      errors: {
        fetch_list: 'Error when trying to fetch statements',
        fetch_details: 'Error when trying to fetch statement',
        fetch_summary: 'Error when trying to fetch statement summary',
        fetch_transactions: 'Error when trying to fetch transactions',
        generate: 'Error generating statements',
        export_csv: 'Error exporting CSV',
        export_file: 'Error exporting file',
        loading_filters: 'Something went wrong when trying to load filters',
      },
      success: {
        generate: 'Statements generated successfully',
      },
      view_details: 'View Details',
      export_details: 'Payout Statement Details',
      export_summary: 'Payout Statement Summary',
      filters: {
        rvp: 'RVP',
        default_rvp_filter: 'All (Default)',
        start_end_date: 'Date Range',
        start_end_date_placeholder: 'Click to choose the date range',
      },
      details: {
        title: 'Payout Statement Details',
        back_list: 'Back to Statements List',
        statement_id: 'Statement ID',
        rvp_name: 'RVP Name',
        rvp_id: 'RVP Salesforce ID',
        rvp_email: 'RVP Email',
        total_balance: 'Total Balance',
        start_date: 'Start Date',
        end_date: 'End Date',
        created_at: 'Created At',
        summary: {
          title: 'Payout Statement Summary',
          empty: 'No results available',
          btn_export: 'Export Summary (.csv)',
          total_amount: 'TOTAL AMOUNT',
          plan_id: 'Plan ID',
          deal_id: 'Deal ID',
          deal: 'Deal',
          channel_deal: 'Channel Deal',
          launch_date: 'Launch Date',
          product: 'Product',
          transaction_type: 'Transaction Type',
          gross_revenue: 'Gross Revenue',
          rev_share: 'Rev Share',
          net_revenue: 'Net Revenue',
          commission: 'Commission',
        },
        transactions: {
          title: 'Payout Statement Transactions',
          empty: 'No results available',
          btn_export: 'Export Details (.csv)',
          id: 'ID',
          deal: 'Deal',
          channel_deal: 'Channel Deal',
          transaction_type: 'Transaction Type',
          deal_product: 'Deal Product',
          commission: 'Commission',
          entry_type: 'Entry Type',
          plan: 'Plan',
          payable_date: 'Payable Date',
          transaction_date: 'Transaction Date',
        },
      },
    },
    transactions: {
      nav_title: 'Transactions',
      btn_export: 'Export',
      btn_export_both: 'Revenue + Booking',
      btn_export_revenue: 'Revenue Transactions',
      btn_export_booking: 'Booking Transactions',
      btn_approve: 'Approve',
      filters: {
        transaction_date_range: 'Transaction Date',
        payable_date_range: 'Payable Date',
        deal_close_date_range: 'Close Date',
        date_range_placeholder: 'Click to choose the date range',
        rvp: 'RVP',
        deal: 'Deal',
        default_rvp_filter: 'All (Default)',
        search: 'Search',
      },
      tabs: {
        revenue: 'Commission Revenue Transactions',
        booking: 'Commission Booking Transactions',
        pending_booking: 'Pending Commission Booking Transactions',
      },
      table: {
        empty: 'No results available',
        id: 'ID',
        deal: 'Deal',
        channel_deal: 'Channel Deal',
        deal_product: 'Deal Product',
        rvp_name: 'RVP Name',
        rvp_rate: 'RVP Rate',
        commission: 'Commission',
        entry_type: 'Entry Type',
        plan: 'Plan',
        payable_date: 'Payable Date',
        close_date: 'Close Date',
        transaction_date: 'Transaction Date',
        transaction_type: 'Transaction Type',
        opportunity_type: 'Type',
        amount: 'Amount',
        carr: 'cARR',
        approval: 'Approval',
      },
      errors: {
        fetch_list: 'Error when trying to fetch transactions',
        export_csv: 'Error exporting CSV',
        loading_rvps: 'Something went wrong when trying to load rvps',
        approve_btn: 'Error when trying to approve transaction',
      },
      btn_modal_confirm_approve: 'Approving will include this Booking Commission on the next statement. Do you wish you proceed?',
      successfully_approve: 'Booking Commission successfully approved',
    },
    deals: {
      nav_title: 'Deals',
      all_deals: {
        empty_list: 'No results available',
        tab_name: 'All',
        id: 'ID',
        sfdc_opportunity_id: 'SFDC Opportunity ID',
        deal_name: 'Deal Name',
        close_date: 'Close Date',
        view_details: 'View Details',
      },
      pending_configs: {
        empty_list: 'No Deals with Pending Configurations',
        tab_name: 'With Pending Configurations',
        id: 'ID',
        sfdc_opportunity_id: 'SFDC Opportunity ID',
        deal_name: 'Deal Name',
        close_date: 'Close Date',
        edit_details: 'Edit Details',
      },
      details: {
        page_title: 'Deal Details - ID',
        deal_id: 'Deal ID',
        deal_name: 'Deal Name',
        close_date: 'Close Date',
        plan_name: 'Plan Name',
        opportunity_type: 'Opportunity Type',
        opportunity_owner: 'Opportunity Owner',
        sfdc_opportunity_id: 'SFDC Opportunity ID',
        sfdc_account_id: 'SFDC Account ID',
        sfdc_parent_opportunity_id: 'SFDC Parent Opportunity ID',
        business_segment: 'Business Segment',
        eligible_lives: 'Eligible Lives',
        carr: 'cARR',
        aso_channel: 'ASO channel',
        aso_channel_live_date: 'ASO channel Live Date',
        aso_channel_deal_id: 'ASO channel Deal ID',
        aso_channel_close_date: 'ASO channel Close Date',
        save_btn: 'Save',
        deal_client_table: {
          title: 'Client / LOB / ASO Group details',
          client_name: 'Client Name',
          line_of_business: 'Line Of Business',
          aso_group: 'Aso Group(s)',
          actions: 'Actions',
          info_badge: 'If both ASO groups and LOB are configured, then both conditions must be met to calculate the commission.',
        },
        split_table: {
          title: 'Deal Splits',
          id: 'ID',
          rvp_name: 'RVP Name',
          split_percentage: 'Split Percentage',
          sfdc_split_id: 'SFDC Split ID',
        },
        product_table: {
          title: 'Deal Products',
          id: 'ID',
          sfdc_opportunity_product_id: 'SFDC Opportunity Product ID',
          product_code: 'Product Code',
          carr: 'cARR',
          eligible_lives: 'Eligible Lives',
          unit: 'Unit',
          marketing_launch_date: 'Marketing Launch Date',
        },
      },
      errors: {
        fetch_list: 'Error when trying to fetch deals',
        fetch_details: 'Error when trying to fetch deal',
        update: 'Error when trying to update deal',
        required_live_date: 'The ASO channel Live Date is required for ASO channel deals',
        required_aso_groups: 'At least one client must be configured in the ASO group details',
        empty_table: 'No results available',
        duplicate_client: 'This client is already configured',
      },
      success: {
        update: 'Deal successfully updated',
      },
      filters: {
        search: 'Search',
        name: 'Deal',
        sfdc_opportunity_id: 'SFDC Opportunity ID',
        close_date: 'Close Date',
      },
    },
    rvps: {
      nav_title: 'RVPs',
      tab_active: 'Active',
      tab_inactive: 'Inactive',
      add_new_rvp: 'Add new RVP',
      table: {
        name: 'RVP Name',
        salesforce_id: 'RVP Salesforce ID',
        title: 'Title',
        email: 'Email',
        manager_name: 'Manager Name',
        empty_list: 'No results available',
      },
      edit_details: 'Edit',
      create_and_details: {
        page_title_create: 'Add new RVP',
        page_title_details: 'RVP details',
        btn_save: 'Save',
        confirm_save: `
          Are you sure you want to inactivate this RVP?
          This means that no new commission transactions will be generated for them.
          Please note that this action is not reversible.
        `,
        form: {
          name: 'RVP Name',
          salesforce_id: 'RVP Salesforce ID',
          title: 'Title',
          email: 'Email',
          is_active: 'Is Active',
        },
      },
      plan_configs: {
        section_title: 'Plan Configurations',
        btn_add: 'Add',
        table: {
          plan: 'Plan',
          key: 'Configuration Key',
          value: 'Value',
          start_date: 'Start Date',
          end_date: 'End Date',
          status: 'Status',
          no_data: 'No plan configurations created',
        },
      },
      errors: {
        fetch_list: 'Error when trying to fetch rvps',
        fetch_details: 'Error when trying to fetch rvp details',
        save: 'Error when trying to save rvp',
        already_exists: 'There is already a RVP with this Salesforce ID, please review this value!',
      },
      success: {
        update: 'RVP updated successfully',
        create: 'RVP created successfully',
        create_plan: 'New plan configuration added',
      },
    },
  },
  finance_operations: {
    sidenav: {
      claims_tasks: 'Claims Tasks',
    },
    claim_accounts: {
      nav_title: 'Claim Accounts',
      list: {
        empty_table: 'No results available',
        client_name: 'Client',
        payer_name: 'Payer',
        billing_launch_date: 'Launch Date',
        assignee: 'Assignee',
        view_details: 'View Details',
        btn_bulk_update: 'Bulk Update',
        bulk_update: {
          form_title: 'Claim Accounts - Bulk Update',
          form_description: 'Claim Accounts were selected. Select the values to be updated.',
          preview_title: 'Claim Accounts - Bulk Update - Confirmation',
          preview_description: 'Claim Accounts will be updated. Please confirm the following values. '
            + 'Note: blank values will override any existing values.',
          assignee: 'Assignee',
          payer_mail: 'Payer Email',
          csm_email: 'CSM Email',
          no_field_to_update_error: 'At least one value must be selected for bulk update',
          no_update: 'No Update',
          blank: '(blank)',
          btn_preview: 'Preview',
          btn_save: 'Save',
          save_success: 'Claim Accounts have been updated',
          save_error: 'Error bulk updating claim accounts',
        },
      },
      details: {
        page_title: 'Claim Account Details',
        account_id: 'Claim Account ID',
        account_name: 'Account Name',
        client_id: 'Client ID',
        client_name: 'Client Name',
        payer_id: 'Payer ID',
        payer_name: 'Payer Name',
        payer_email: 'Payer Email',
        assignee: 'Assignee',
        csm_email: 'CSM Email',
        btn_save: 'Save',
      },
      errors: {
        fetch_list: 'Error when trying to fetch claim accounts',
        fetch_details: 'Error when trying to fetch claim account details',
        save: 'Error when trying to save claim account',
      },
      success: {
        save: 'Claim account saved successfully',
      },
      filters: {
        search: 'Search',
        assignee: 'Assignee',
        payer: 'Payer',
        default_assignee_filter: 'All',
        default_payer_filter: 'All',
        blank_option_assignee_filter: 'Unassigned',
        error_loading_payers: 'Error when trying to fetch payers',
      },
    },
    claims_follow_up_tasks: {
      nav_title: 'Claims Follow Up Tasks',
      tabs: {
        open_tasks: 'Open Tasks',
        completed_tasks: 'Completed Tasks',
      },
      list: {
        empty_table: 'No results available',
        claim_id: 'Claim ID',
        client_name: 'Client',
        payer_name: 'Payer',
        assignee: 'Assignee',
        claim_submitted_date: 'Submitted Date',
        billed_amount: 'Billed Amount',
        paid_amount: 'Paid Amount',
        task_status: 'Task Status',
        next_action: 'Next Action',
        next_action_due_date: 'Next Action Due Date',
        view_details: 'View Details',
        completed_date: 'Completed Date',
        btn_export: 'Export',
        btn_bulk_update: 'Bulk Update',
        bulk_update: {
          form_title: 'Claims Follow Up - Bulk Update',
          form_description: 'tasks were selected. Select the values to be updated.',
          preview_title: 'Claims Follow Up - Bulk Update - Confirmation',
          preview_description: 'tasks will be updated. Please confirm the following values. '
            + 'Note: blank values will override any existing values',
          status: 'Status',
          next_action: 'Next Action',
          next_action_due_date: 'Next Action Due Date',
          next_action_due_date_placeholder: 'Click to choose date',
          note: 'Note',
          no_field_to_update_error: 'At least one value must be selected for bulk update',
          no_update: 'No Update',
          blank: '(blank)',
          btn_preview: 'Preview',
          btn_save: 'Save',
          save_success: 'Task(s) Successfully Updated',
          save_error: 'Error bulk update claim follow up tasks',
        },
      },
      details: {
        page_title: 'Follow Up Task Details',
        btn_modal_write_off: 'Write Off',
        collapse_all: 'Collapse',
        expand_all: 'Expand',
        all: 'all',
        section_task_details: {
          title: 'Task Details',
          status: 'Status',
          next_action: 'Next Action',
          next_action_due_date: 'Next Action Due Date',
          next_action_due_date_placeholder: 'Click to choose date',
          save: 'Save',
          claim_account_assignee: 'Assignee',
          claim_account_name: 'Account Name',
          claim_account_payer_external_id: 'Payer External ID',
          task_id: 'Task ID',
          write_off_task_id: 'Claim Write Off Task ID',
          created_at: 'Created Date',
          completed_date: 'Completed Date',
          claim_account_payer_email: 'Payer Email',
          claim_account_csm_email: 'CSM Email',
        },
        section_claim_information: {
          title: 'Claim Information',
          first_name: 'First Name',
          last_name: 'Last Name',
          dob: 'DOB',
          member_insurance_id: 'Member Insurance ID',
          claim_external_id: 'Claim External ID',
          submitted_date: 'Submitted Date',
          billed_amount: 'Billed Amount',
          paid_amount: 'Paid Amount',
          claim_batch_id: 'Claim Batch ID',
          gender: 'Gender',
          relationship_to_insured: 'Relationship to Insured',
          diagnosis_code: 'Diagnosis Code',
          pt_npi: 'PT NPI',
          claim_transaction_table: {
            from_date: 'From Date',
            to_date: 'To Date',
            pos: 'POS',
            cpt: 'CPT',
            modifier: 'Modifier',
            charges: 'Charges',
            quantity: 'Quantity',
            empty: 'No results available',
          },
          error_fetching_claim: 'Error fetching claim information',
          no_claim: 'Associated claim is no longer valid',
        },
        section_remit_information: {
          title: 'Claim Remit Information',
          no_claim: 'Associated claim is no longer valid',
          no_remits: 'The claim does not have any remittance information available.',
          table_header_sword_claim_id: 'SWORD Claim ID',
          table_header_payer_name: 'Payer',
          table_header_payer_claim_id: 'Payer Claim ID',
          table_header_payment_date: 'Paid Date',
          table_header_paid_amount: 'Paid amount',
          table_header_check_number: 'Check Number',
          net_payment: 'Net Payment',
        },
        section_finance_information: {
          title: 'Finance Information',
          missing_claim: 'Associated claim is no longer valid',
          error_fetching_claim: 'Error fetching claim information',
          program_id: 'Program ID',
          account_uuid: 'Account UUID',
          statement_id: 'Statement ID',
          transactions_header_id: 'Transaction ID',
          transactions_header_created_at: 'Created At',
          transactions_header_event_date: 'Transaction Date',
          transactions_header_billable_date: 'Billable Date',
          transactions_header_transaction_type: 'Transaction Type',
          transactions_header_unit: 'Unit',
          transactions_header_therapy_name: 'Therapy',
          transactions_header_transaction_value: 'Value',
          transactions_header_transaction_value_type: 'Entry Type',
          no_transactions: 'No transactions',
        },
        section_notes: {
          title: 'Notes',
          save_note: 'Save',
          bulk_note: 'Bulk Note',
        },
      },
      filters: {
        claim_id: 'Claim ID',
        assignee: 'Assignee',
        btn_search: 'Search',
        default_all: 'All',
        blank_option_assignee_filter: 'Unassigned',
        client: 'Client',
        payer: 'Payer',
      },
      errors: {
        fetch_list: 'Error when trying to fetch claim follow up tasks',
        fetch_details: 'Error when trying to fetch claim follow up task details',
        save: 'Error when trying to save claim follow up task',
        fetch_notes: 'Error when trying to fetch claim follow up task notes',
        save_note: 'Error saving new note',
        fetch_remits: 'Error when trying to fetch claim remit information',
        export_list: 'Error when trying to export claim follow up tasks',
        fetch_clients: 'Error when trying to fetch clients',
        fetch_payers: 'Error when trying to fetch payers',
      },
      success: {
        save: 'Claim follow up task saved successfully',
      },
    },
    claim_write_off_tasks: {
      nav_title: 'Claim Write Off Tasks',
      modal_create: {
        btn_save: 'Save',
        btn_cancel: 'Cancel',
        title: 'Create Write Off Task',
        claim_id: 'Claim ID',
        claim_account: 'Claim Account',
        billed_amount: 'Billed Amount',
        paid_amount: 'Paid Amount',
        write_off_amount: 'Write Off Amount',
        reason: 'Reason',
        note: 'Note',
      },
      tabs: {
        pending_approval: 'Pending Approval',
        approved: 'Approved',
      },
      list: {
        empty: 'No results available',
        claim_external_id: 'Claim External ID',
        client_name: 'Client Name',
        payer_name: 'Payer Name',
        assignee: 'Assignee',
        claim_submitted_date: 'Submitted Date',
        billed_amount: 'Billed Amount',
        paid_amount: 'Paid Amount',
        write_off_amount: 'Write Off Amount',
        reason: 'Reason',
        next_action: 'Next Action',
        next_action_due_date: 'Next Action Due Date',
        approved_date: 'Approved Date',
        view_details: 'Details',
      },
      details: {
        page_title: 'Task Details',
        collapse_all: 'Collapse',
        expand_all: 'Expand',
        all: 'all',
        details_section_task_details: {
          save: 'Save',
          title: 'Task Details',
          assignee: 'Assignee',
          status: 'Status',
          reason: 'Reason',
          next_action: 'Next Action',
          next_action_due_date: 'Next Action Due Date',
          next_action_due_date_placeholder: 'Click to choose date',
          account_name: 'Account Name',
          payer_external_id: 'Payer External ID',
          task_id: 'Task ID',
          created_date: 'Created Date',
          approved_date: 'Approved Date',
          claim_follow_up_task_id: 'Claim Follow Up Task ID',
        },
        details_section_finance_information: {
          title: 'Finance Information',
          program_id: 'Program ID',
          billed_amount: 'Billed Amount',
          paid_amount: 'Paid Amount',
          write_off_amount: 'Write Off Amount',
        },
        details_section_notes: {
          title: 'Notes',
          save_note: 'Save',
        },
        errors: {
          fetch: 'Error when trying to fetch write off task',
          save: 'Error when trying to save write off task',
          fetch_notes: 'Error when trying to fetch claim write off task notes',
          save_note: 'Error saving new note',
        },
        success: {
          save: 'Write off task saved successfully',
        },
      },
      errors: {
        create: 'Error when trying to create write off task',
        fetch_list: 'Error when trying to fetch write off tasks',
      },
      success: {
        create: 'Write off task created successfully',
      },
    },
  },
  onboarding: {
    client_form_header: {
      activate_client: 'Activate Client',
      billing: 'Billing',
      configuration: 'configuration',
      deactivate_client: 'Deactivate Client',
      eligibility: 'Eligibility',
      medical_claims: 'Medical Claims',
      pharmacy_claims: 'Pharmacy Claims',
      generic_importer: 'Generic Importer',
      extra_configuration: 'Extra Configuration',
      integrations: 'Integrations',
      onboarding_page: 'Onboarding page',
      new_client: 'New client',
      save: 'Save',
      test_eligibility: 'Test eligibility',
    },
    edit_client: {
      account_manager: 'Account Manager',
      account_manager_email: 'Email',
      account_manager_email_description: 'The email address that will receive the onboarding emails',
      account_manager_name: 'Name',
      account_manager_name_description: 'Account manager name for this client email communications',
      allow_phone_call: 'Allow phone call?',
      enable_cc_support: 'Enable CC support?',
      available_languages: 'Available Languages',
      available_professionals: 'therapists are available for onboarding. Click to show details',
      billing_configurations_title: 'Billing configuration',
      billing_configurations_settings_title: 'Billing configuration',
      billing_configurations_settings_link: 'Click here to configure client billing settings',
      bloom_client: 'Bloom Client',
      capacity: 'Capacity',
      change_logo: 'Change logo',
      change_logo_placeholder: 'Choose a file or drop it here...',
      charge_only_one_program: 'Charge only one program',
      client_name: 'Client name',
      client_name_description: 'Enter the client name',
      client_level_access: 'Client data level access',
      country: 'Country',
      country_required: 'Client country is required',
      date_format: 'Date format',
      default_language: 'Default language',
      level_access: {
        normal: 'Normal',
        normal_description: ': Client data that can be accessed outside US (Standard).',
        strict: 'Strict - Zero Tolerance',
        strict_description: ': Client data (PHI and other data based on contract) that cannot be accessed outside US',
        strict_short_name: 'Strict',
        zendesk_widget: 'Zendesk widget',
      },
      clientless_institutions: 'We sorry, but while there aren\'t free institutions to assign, you can\'t create new clients.',
      edit_institution: 'Edit this on institution edit page',
      eligibility_title: 'Eligibility',
      eligibility_settings_title: 'Eligibility settings moved into a separate page.',
      eligibility_settings_link: 'Click here to configure eligibility',
      errors: {
        failed_to_activate_client: 'Failed to activate client',
        failed_to_deactivate_client: 'Failed to deactivate client',
      },
      first_hours_available: 'First slot available distance in hours',
      first_hours_available_description: 'Distance in hours for the first slot available to schedule (Default is 12h when empty)',
      first_hours_available_invalid_feedback: 'First slot available distance in hours',
      general_info: 'General Info & Appearance',
      telehealth_consent: 'Consent to Telehealth Services',
      telehealth_consent_description: 'Consent to Telehealth Services URL',
      institution: 'Institution',
      integrations_title: 'Integrations',
      integrations_settings_title: 'Integration with external ecosystems moved into a separate page.',
      integrations_settings_link: 'Click here to configure integrations',
      irb: 'Informed Consent Research',
      irb_description: 'Informed Consent Research URL',
      loading: 'Loading...',
      localization: 'Localization',
      onboarding_version: 'Onboarding version',
      no_therapists: 'This institution does not have professionals available for onboarding',
      member_invitations_title: 'Member Invitations',
      member_invitations_link: 'Click here to configure member invitations',
      non_discrimination: 'Non Discrimination',
      non_discrimination_description: 'Non Discrimination URL',
      number_of_days_to_schedule: 'Number of Days to Schedule',
      parent_client: 'Parent Client',
      parent_client_description: 'Select a parent for this client.',
      hipaa_consent: 'HIPAA Notice of Privacy Practices ',
      hipaa_consent_description: 'HIPAA Notice URL',
      provided_service_type: 'Provided service type',
      provided_call_type: 'Provided call type',
      saved: 'Saved',
      show_powered_by_sword: 'Show powered by SWORD Health',
      hide_client_logo: 'Hide client logo',
      show_bloom_logo: 'Show Bloom Logo',
      support_email: 'Support email',
      support_email_description: 'The email address for support that appears on page',
      support_phone_number: 'Support phone number',
      support_phone_number_description: 'The phone number for support that appears on page',
      sword_client: 'Sword Client',
      sword_client_description: 'Select the corresponding Sword client.',
      terms_and_conditions: 'Terms and Conditions',
      terms_and_condition_description: 'Terms and Conditions URL',
      therapists_modal_active_patients: 'active patients on all institutions',
      therapists_modal_email: 'Email',
      therapists_modal_number_patients: '# of patients',
      therapists_modal_onboarding: 'Onboarding',
      therapists_modal_title: 'List of therapists',
      therapists_modal_username: 'Username',
      therapy_settings_title: 'Therapy Settings',
      therapy_settings_description: 'Therapies available on the onboarding page',
      time_format: 'Time format',
      time_zone: 'Time zone',
      toggles: 'Toggles',
      video_call_duration: 'Video call duration',
      video_call_buffer: 'Video call buffer',
      video_call_duration_description: 'Duration for the video call slots in minutes. Must be a number greater than 0',
      video_call_buffer_description: 'Buffer in minutes that we need to keep between video calls. Can be greater or equal to 0',
      video_call_settings_title: 'Video Call Settings',
      form_configuration: 'Onboarding Form Configuration',
      form_configuration_injury_area: 'Injury Area',
      form_configuration_main_form: 'Main form',
      form_configuration_complementary_form: 'Complementary form',
      ankle: 'Ankle',
      low_back: 'Low back',
      shoulder: 'Shoulder',
      elbow: 'Elbow',
      wrist_hand: 'Wrist/Hand',
      hip: 'Hip',
      knee: 'Knee',
      pelvic: 'Pelvic',
      falls_prevention: 'Falls prevention',
      neck: 'Neck',
    },
  },
  orders: {
    status: {
      new: 'New',
      invalid: 'Invalid',
      pending_order_retry: 'Pending Order',
      failed: 'Failed',
      out_of_stock: 'Out of Stock',
      canceled: 'Canceled',
      created: 'Created',
      shipped: 'Shipped',
      delivered: 'Delivered',
      delayed: 'Delayed',
      failed_delivery: 'Failed Delivery',
      returned_to_sender: 'Returned to Sender',
      returned: 'Returned',
      archived: 'Archived',
      available_for_pickup: 'Available for Pickup',
    },
    method: {
      auto: 'Auto',
      manual: 'Manual',
    },
    actions: {
      archive: 'Archive',
      replace_order: 'Replace Order',
      update_to: 'Update to',
      allow_schedule_pickup: 'Schedule Pickup',
      allow_cancel_pickup: 'Cancel Pickup',
      allow_create_return_label: 'Create Return Label',
      allow_archive_order: 'Archive Order',
      allow_add_production_info: 'Add Production Info',
      allow_add_distribution_info: 'Add Distribution Info',
      allow_edit_distribution_info: 'Edit Distribution Info',
      allow_add_distribution_info_with_extra_fields: 'Add Distribution Info',
      allow_cancel_order: 'Cancel Order',
      allow_change_status_to_returned: 'Update to Returned',
      allow_change_status_to_on_hold: 'Update to On Hold',
      allow_change_status_to_request_sent: 'Update to Request Sent',
      allow_change_status_to_new: 'Update to New',
      allow_change_status_to_created: 'Update to Created',
      allow_change_status_to_failed: 'Update to Failed',
      allow_change_status_to_invalid: 'Update to Invalid',
      allow_change_status_to_delayed: 'Update to Delayed',
      allow_change_status_to_out_of_stock: 'Update to Out of Stock',
      allow_change_status_to_returned_to_sender: 'Update to Returned to Sender',
      allow_change_status_to_shipped: 'Update to Shipped',
      allow_change_status_to_pending_order_retry: 'Update to Pending Order',
      allow_change_status_to_delivered: 'Update to Delivered',
      allow_change_status_to_failed_delivery: 'Update to Failed Delivery',
      allow_add_tracking: 'Add Tracking',
      allow_replace_order: 'Replace Order',
    },
    type: {
      forward: 'Forward',
      return: 'Return',
    },
    component_type: {
      kit: 'Kit',
      spare_part: 'Spare Part',
    },
    spare_parts: {
      category: {
        bloom_spare_parts: 'Bloom Spare Parts',
        charger: 'Charger',
        holder: 'Holder',
        hotspot: 'Hotspot',
        move: 'Move',
        tablet_stand: 'Tablet Stand',
        strap: 'Strap',
        cable: 'Cable',
        shipping_materials: 'Shipping Materials',
      },
    },
    therapy_settings_description: {
      production_info: 'Production Info',
    },
    tags: {
      production_info: 'Production Info',
      distribution_info: 'Distribution Info',
      manual_update_returned: 'Manual Update to Returned',
      returned_not_confirmed: 'Returned Not Confirmed',
      archived: 'Archived',
      predict: 'Predict',
      delivery_confirmed_by_member: 'Delivery Confirmed by Member',
      sku_updated: 'SKU Updated',
      created: 'Created',
    },
  },
  returns: {
    status: {
      new: 'New',
      request_sent: 'Request Sent',
      shipped: 'Shipped',
      review: 'Review',
      canceled: 'Canceled',
      lost: 'Lost',
      returned: 'Returned',
      return_label_not_available: 'Return Label N/A',
      tracking_failed: 'Tracking Failed',
      on_hold: 'On Hold',
      failed_delivery: 'Failed Delivery',
    },
    actions: {
      allow_schedule_pickup: 'Schedule Pickup',
      allow_create_return_label: 'Create Return Label',
    },
  },
  ship_vias: {
    methods: {
      auto: 'Auto Ship Via',
      late_onboarding: 'Late OB Ship Via',
      manual: 'Manual Ship Via',
    },
  },
  components: {
    semi_kit_names: {
      semi_kit_lower_limb: 'Semi Kit Lower Limb',
      semi_kit_upper_limb: 'Semi Kit Upper Limb',
      semi_kit_neck: 'Semi Kit Neck',
      semi_kit_low_back: 'Semi Kit Low Back',
      semi_kit_wrist: 'Semi Kit Wrist',
      semi_kit_optical: 'Semi Kit Optical',
    },
  },
  revision_requests: {
    client: {
      page_title: 'Page Title',
      id: 'General | General | Client ID',
      'configuration.salesforce_account_name': 'General | General | Account Name',
      display_name: 'General | General | Client Name',
      reference: 'General | General | Client Key',
      parent_id: 'General | General | Parent Client',
      institution_id: 'General | General | Institution ID',
      'configuration.onboarding_version': 'General | General | Onboarding Version',
      'configuration.client_mode': 'General | General | Client Mode',
      'configuration.support_email': 'General | Support | Support Email',
      'configuration.support_phone_number': 'General | Support | Support Phone Number',
      rvp_email: 'General | Support | Enrollment Email',
      rvp_name: 'General | Contact | Customer Success Manager',
      'configuration.industry_broad': 'General | Contact | Industry Broad',
      'configuration.industry_sub': 'General | Contact | Industry Sub',
      'configuration.institution_type': 'General | Contact | Institution Type',
      'configuration.institution_category': 'General | Contact | Institution Category',
      'configuration.client_email': 'General | Contact | Client Email',
      'configuration.client_web_address': 'General | Contact | Client web address',
      'configuration.client_phone': 'General | Contact | Client Phone',
      'configuration.client_address': 'General | Contact | Client Address',
      'configuration.client_tier': 'General | Contact | Client Tier',
      'configuration.salesforce_account_id': 'General | Contact | 18 Digit Account ID',
      'configuration.salesforce_parent_account_id': 'General | Contact | Parent 18 Digit Account ID',
      'configuration.custom_client_title': 'Enrollment | Custom title on registration page',
      'configuration.custom_navigator_titles': 'Enrollment | Custom titles on navigator',
      'configuration.navigator_hide_non_eligible_units': 'Enrollment | Hide non eligible units on navigator',
      'configuration.member_app_signup_experience': 'Enrollment | Member-app signup experience',
      'configuration.member_app_enrollment_experience': 'Enrollment | Member-app enrollment experience',
      'configuration.navigator_mode': 'Enrollment | Navigator behaviour',
      'configuration.toggles.show_get_started_pages': 'Enrollment | Show Get Started pages',
      'configuration.toggles.conservative_mode': 'Enrollment | Conservative mode',
      'configuration.toggles.show_member_id_field': 'Enrollment | Show Health Insurance ID field',
      'configuration.toggles.require_subscriber_dependent_information': 'Enrollment | Require subscriber dependent information',
      'configuration.toggles.show_relationship_field_on_onboarding': 'Enrollment | Show relationship field when member is a dependent',
      'configuration.toggles.required_member_id_field': 'Enrollment | Required member Id field',
      'configuration.toggles.show_payers_list': 'Enrollment | Show payers list',
      'configuration.toggles.care_coordinator_enabled': 'Enrollment | Care pathway on member invitation page',
      'configuration.toggles.show_wizard': 'Enrollment | Show wizard',
      'configuration.toggles.hide_client_logo': 'Enrollment | Hide client logo',
      'configuration.toggles.always_show_navigator': 'Enrollment | Always show navigator',
      'configuration.toggles.hide_payer_insurance_fields': 'Enrollment | Hide payer and insurance ID fields for members only eligible for services',
      'configuration.country': 'Localization | Country',
      'configuration.language': 'Localization | Default Language',
      'configuration.available_languages': 'Localization | Available Languages',
      'configuration.institution_timezone': 'Localization | Timezone',
      'configuration.date_format': 'Localization | Date Format',
      'configuration.time_format': 'Localization | Time Format',
      'configuration.timezone': 'Localization | Appointments Timezone',
      'configuration.toggles.global_client': 'Localization | Global Client',
      data_access: 'Legal | General | Data access',
      'configuration.toggles.enable_health_integration_exchange': 'Legal | General | Enable health integration exchange',
      'configuration.legal_links.en.irb_consent': 'Legal | en docs | Irb consent',
      'configuration.legal_links.en.hipaa_consent': 'Legal | en docs | Hipaa consent',
      'configuration.legal_links.en.non_discrimination': 'Legal | en docs | Non discrimination',
      'configuration.legal_links.en.telehealth_consent': 'Legal | en docs | Telehealth consent',
      'configuration.legal_links.en-GB.irb_consent': 'Legal | en-GB docs | Irb consent',
      'configuration.legal_links.en-GB.hipaa_consent': 'Legal | en-GB docs | Hipaa consent',
      'configuration.legal_links.en-GB.non_discrimination': 'Legal | en-GB docs | Non discrimination',
      'configuration.legal_links.en-GB.telehealth_consent': 'Legal | en-GB docs | Telehealth consent',
      'configuration.legal_links.en-GB.terms_and_conditions': 'Legal | en-GB docs | Terms and conditions',
      'configuration.legal_links.es.irb_consent': 'Legal | es docs | Irb consent',
      'configuration.legal_links.es.hipaa_consent': 'Legal | es docs | Hipaa consent',
      'configuration.legal_links.es.non_discrimination': 'Legal | es docs | Non discrimination',
      'configuration.legal_links.es.telehealth_consent': 'Legal | es docs | Telehealth consent',
      'configuration.legal_links.es.terms_and_conditions': 'Legal | es docs | Terms and conditions',
      'configuration.legal_links.fr-CA.irb_consent': 'Legal | fr-CA docs | Irb consent',
      'configuration.legal_links.fr-CA.hipaa_consent': 'Legal | fr-CA docs | Hipaa consent',
      'configuration.legal_links.fr-CA.non_discrimination': 'Legal | fr-CA docs | Non discrimination',
      'configuration.legal_links.fr-CA.telehealth_consent': 'Legal | fr-CA docs | Telehealth consent',
      'configuration.legal_links.fr-CA.terms_and_conditions': 'Legal | fr-CA docs | Terms and conditions',
      'configuration.legal_links.pt.irb_consent': 'Legal | pt docs | Irb consent',
      'configuration.legal_links.pt.hipaa_consent': 'Legal | pt docs | Hipaa consent',
      'configuration.legal_links.pt.non_discrimination': 'Legal | pt docs | Non discrimination',
      'configuration.legal_links.pt.telehealth_consent': 'Legal | pt docs | Telehealth consent',
      'configuration.legal_links.pt.terms_and_conditions': 'Legal | pt docs | Terms and conditions',
      'configuration.legal_links.en.terms_and_conditions': 'Legal | en docs | Terms and conditions',
      'configuration.toggles.send_drop_off_emails': 'Marketing | Send drop-off emails',
      'configuration.toggles.show_on_marketing_pages': 'Marketing | Show on marketing pages',
      'configuration.marketing.emailAllowed': 'Marketing | Email allowed',
      'configuration.marketing.smsAllowed': 'Marketing | SMS allowed',
      'configuration.marketing.industry': 'Marketing | Industry',
      'configuration.marketing.tier': 'Marketing | Client tier',
      'configuration.marketing.enrollmentCampaignType': 'Marketing | Enrollment campaign type',
      'configuration.marketing.employeeReferenceName': 'Marketing | Employee reference name',
      'configuration.marketing.landingPageURL': 'Marketing | Landing page URL',
      'configuration.dpt.enabled': 'DPT | General | Is DPT enabled',
      'configuration.dpt.service_types': 'DPT | General | Service types',
      'configuration.dpt.credentialRequired': 'DPT | General | Assigned PT is required institution-specific credential',
      'configuration.dpt.credential_group_id': 'DPT | General | Credential group id',
      'configuration.dpt.support_email': 'DPT | General | Support Email',
      'configuration.dpt.toggles.kit_shipped_only_after_video_call': 'DPT | General | Kit shipped after call (if applicable)',
      'configuration.dpt.navigator_visible': 'DPT | General | Show on navigator even if it is not available',
      'configuration.dpt.institution_configs.email_performances': 'DPT | General | Email performances',
      'configuration.dpt.institution_configs.email_professional_after_session': 'DPT | General | Email professional after session',
      'configuration.dpt.institution_configs.email_suggestions': 'DPT | General | Email suggestions',
      'configuration.dpt.institution_configs.send_sms_reminders': 'DPT | General | Send sms reminders',
      'configuration.dpt.toggles.allow_minor_enrollments': 'DPT | General | Allow enrollments by minors',
      'configuration.dpt.capacity': 'DPT | General | Capacity',
      'configuration.dpt.salesforce_account_id': 'DPT | General | SalesForce program id',
      'configuration.dpt.technical_launch_date': 'DPT | General | Technical launch date',
      'configuration.dpt.marketing_launch_date': 'DPT | General | Marketing launch date',
      'configuration.dpt.unique_addressable_lives': 'DPT | General | Unique Addressable Lives',
      'configuration.dpt.academy_enabled': 'DPT | Member app | Academy enabled',
      'configuration.dpt.on_call_chat': 'DPT | Member app | On call chat enabled',
      'configuration.dpt.institution_configs.sword_go_enabled': 'DPT | Member app | Allow GO Sessions',
      'configuration.dpt.show_on_navigator': 'DPT | Member app | Show on navigator/hub',
      'configuration.dpt.cbt_program': 'DPT | Member app | CBT Program',
      'configuration.dpt.allow_phone_call': 'DPT | Call | Allow phone call',
      'configuration.dpt.toggles.show_fake_slots': 'DPT | Call | Show fake slots',
      'configuration.dpt.toggles.can_skip_video_call': 'DPT | Call | Can skip call',
      'configuration.dpt.toggles.enable_sendbird_video_call': 'DPT | Call | Sendbird video calls',
      'configuration.dpt.call_type': 'DPT | Call | Video call PT and treatment PT',
      'configuration.dpt.number_of_days_to_schedule': 'DPT | Call | Number of days to schedule',
      'configuration.dpt.video_call.buffer': 'DPT | Call | Interval between calls',
      'configuration.dpt.video_call.duration': 'DPT | Call | Call duration',
      'configuration.dpt.minimum_scheduling_distance_hours': 'DPT | Call | First slot available distance in hours',
      'configuration.dpt.video_call.pt_provider_duration': 'DPT | Call | PT provider call duration',
      'configuration.dpt.institution_configs.automatic_form_mapping': 'DPT | Clinical | Automatic form mapping',
      'configuration.dpt.toggles.show_how_long_experiencing_pain_field': 'DPT | Clinical | Show how long experiencing pain field',
      'configuration.dpt.toggles.bypass_clinical_referral_rules': 'DPT | Clinical | Bypass clinical referral rules',
      'configuration.dpt.toggles.bypass_pt_regulation_status': 'DPT | Clinical | Bypass PT Regulation Status',
      'configuration.dpt.institution_configs.automatic_reassessments': 'DPT | Clinical | Automatic reassessments',
      'configuration.dpt.institution_configs.disable_prescription_justification': 'DPT | Clinical | Disable Prescription Justification',
      'configuration.dpt.institution_configs.gad_7': 'DPT | Clinical | GAD-7 > x',
      'configuration.dpt.institution_configs.phq_9': 'DPT | Clinical | PHQ-9 > x',
      'configuration.dpt.institution_configs.hurting_thoughts': 'DPT | Clinical | Consider Hurting Thoughts',
      'configuration.dpt.toggles.enable_optical_therapies': 'DPT | Clinical | Enable Computer Vision Therapies',
      'configuration.dpt.conditionsMapping': 'DPT | Conditions',
      'configuration.dpt.onboarding_type': 'DPT | General | Onboarding type',
      'configuration.dpt.onboarding_forms.updated_at': 'DPT | Conditions | Updated at',
      'configuration.dpt.onboarding_forms.injury_areas.ankle': 'DPT | Conditions | Ankle',
      'configuration.dpt.onboarding_forms.injury_areas.ankle.onboarding_complementary_form_key': 'DPT | Conditions | Ankle | Complementary form',
      'configuration.dpt.onboarding_forms.injury_areas.ankle.onboarding_regular_form_key': 'DPT | Conditions | Ankle | Main form',
      'configuration.dpt.onboarding_forms.injury_areas.elbow': 'DPT | Conditions | Elbow',
      'configuration.dpt.onboarding_forms.injury_areas.elbow.onboarding_complementary_form_key': 'DPT | Conditions | Elbow | Complementary form',
      'configuration.dpt.onboarding_forms.injury_areas.elbow.onboarding_regular_form_key': 'DPT | Conditions | Elbow | Main form',
      'configuration.dpt.onboarding_forms.injury_areas.falls_prevention': 'DPT | Conditions | Falls prevention',
      // eslint-disable-next-line max-len
      'configuration.dpt.onboarding_forms.injury_areas.falls_prevention.onboarding_complementary_form_key': 'DPT | Conditions | Falls prevention | Complementary form',
      // eslint-disable-next-line max-len
      'configuration.dpt.onboarding_forms.injury_areas.falls_prevention.onboarding_regular_form_key': 'DPT | Conditions | Falls prevention | Main form',
      'configuration.dpt.onboarding_forms.injury_areas.hip': 'DPT | Conditions | Hip',
      'configuration.dpt.onboarding_forms.injury_areas.hip.onboarding_complementary_form_key': 'DPT | Conditions | Hip | Complementary form',
      'configuration.dpt.onboarding_forms.injury_areas.hip.onboarding_regular_form_key': 'DPT | Conditions | Hip | Main form',
      'configuration.dpt.onboarding_forms.injury_areas.knee': 'DPT | Conditions | Knee',
      'configuration.dpt.onboarding_forms.injury_areas.knee.onboarding_complementary_form_key': 'DPT | Conditions | Knee | Complementary form',
      'configuration.dpt.onboarding_forms.injury_areas.knee.onboarding_regular_form_key': 'DPT | Conditions | Knee | Main form',
      'configuration.dpt.onboarding_forms.injury_areas.low_back': 'DPT | Conditions | Low back',
      // eslint-disable-next-line max-len
      'configuration.dpt.onboarding_forms.injury_areas.low_back.onboarding_complementary_form_key': 'DPT | Conditions | Low back | Complementary form',
      'configuration.dpt.onboarding_forms.injury_areas.low_back.onboarding_regular_form_key': 'DPT | Conditions | Low back | Main form',
      'configuration.dpt.onboarding_forms.injury_areas.neck': 'DPT | Conditions | Neck',
      'configuration.dpt.onboarding_forms.injury_areas.neck.onboarding_complementary_form_key': 'DPT | Conditions | Neck | Complementary form',
      'configuration.dpt.onboarding_forms.injury_areas.neck.onboarding_regular_form_key': 'DPT | Conditions | Neck | Main form',
      'configuration.dpt.onboarding_forms.injury_areas.pelvic': 'DPT | Conditions | Pelvic',
      'configuration.dpt.onboarding_forms.injury_areas.pelvic.onboarding_complementary_form_key': 'DPT | Conditions | Pelvic | Complementary form',
      'configuration.dpt.onboarding_forms.injury_areas.pelvic.onboarding_regular_form_key': 'DPT | Conditions | Pelvic | Main form',
      'configuration.dpt.onboarding_forms.injury_areas.shoulder': 'DPT | Conditions | Shoulder',
      // eslint-disable-next-line max-len
      'configuration.dpt.onboarding_forms.injury_areas.shoulder.onboarding_complementary_form_key': 'DPT | Conditions | Shoulder | Complementary form',
      'configuration.dpt.onboarding_forms.injury_areas.shoulder.onboarding_regular_form_key': 'DPT | Conditions | Shoulder | Main form',
      'configuration.dpt.onboarding_forms.injury_areas.wrist_hand': 'DPT | Conditions | Wrist hand',
      // eslint-disable-next-line max-len
      'configuration.dpt.onboarding_forms.injury_areas.wrist_hand.onboarding_complementary_form_key': 'DPT | Conditions | Wrist hand | Complementary form',
      'configuration.dpt.onboarding_forms.injury_areas.wrist_hand.onboarding_regular_form_key': 'DPT | Conditions | Wrist hand | Main form',
      'configuration.dpt.high_risk.allow_phone_call': 'DPT | High risk | Allow phone call',
      'configuration.dpt.high_risk.can_skip_video_call': 'DPT | High risk | Can skip call',
      'configuration.dpt.high_risk.video_call_duration': 'DPT | High risk | Call duration',
      'configuration.dpt.wheel_balance': 'DPT | Wheel % distribution',
      'configuration.dpt.openloop_balance': 'DPT | Openloop % distribution',
      'configuration.dpt_go.enabled': 'DPT Go | General | Is DPT Go enabled',
      'configuration.dpt_go.support_email': 'DPT Go | General | Support Email',
      'configuration.dpt_go.credential_group_id': 'DPT Go | General | Support Email',
      'configuration.dpt_go.institution_configs.sword_go_enabled': 'DPT Go | Member app | Allow GO Sessions',
      'configuration.dpt_go.academy_enabled': 'DPT Go | Member app | Academy enabled',
      'configuration.dpt_go.on_call_chat': 'DPT Go | Member app | On call chat enabled',
      'configuration.dpt_go.cbt_program': 'DPT Go | Member app | CBT Program',
      'configuration.dpt_go.conditionsMapping': 'DPT Go | Conditions',
      'configuration.bloom.enabled': 'Bloom | General | Is Bloom enabled',
      'configuration.bloom.support_email': 'Bloom | General | Support Email',
      'configuration.bloom.toggles.kit_shipped_only_after_video_call': 'Bloom | General | Kit shipped after call (if applicable)',
      'configuration.bloom.navigator_visible': 'Bloom | General | Show on navigator even if it is not available',
      'configuration.bloom.institution_configs.send_sms_reminders': 'Bloom | General | Send sms reminders',
      'configuration.bloom.toggles.allow_minor_enrollments': 'Bloom | General | Allow enrollments by minors',
      'configuration.bloom.force_eco_experience': 'Bloom | General | Force ECO Experience',
      'configuration.bloom.capacity': 'Bloom | General | Capacity',
      'configuration.bloom.redirect_url': 'Bloom | General | Redirect url',
      'configuration.bloom.salesforce_account_id': 'Bloom | General | SalesForce program id',
      'configuration.bloom.technical_launch_date': 'Bloom | General | Technical launch date',
      'configuration.bloom.marketing_launch_date': 'Bloom | General | Marketing launch date',
      'configuration.bloom.unique_addressable_lives': 'Bloom | General | Unique Addressable Lives',
      'configuration.bloom.academy_enabled': 'Bloom | Member app | Academy enabled',
      'configuration.bloom.on_call_chat': 'Bloom | Member app | On call chat enabled',
      'configuration.bloom.show_on_navigator': 'Bloom | Member app | Show on navigator/hub',
      'configuration.bloom.cbt_program': 'Bloom | Member app | CBT Program',
      'configuration.bloom.toggles.enable_sendbird_video_call': 'Bloom | Call | Sendbird video calls',
      'configuration.bloom.number_of_days_to_schedule': 'Bloom | Call | Number of days to schedule',
      'configuration.bloom.video_call.buffer': 'Bloom | Call | Interval between calls',
      'configuration.bloom.video_call.duration': 'Bloom | Call | Call duration',
      'configuration.bloom.minimum_scheduling_distance_hours': 'Bloom | Call | First slot available distance in hours',
      'configuration.bloom.institution_configs.automatic_reassessments': 'Bloom | Clinical | Automatic reassessments',
      'configuration.bloom.institution_configs.gad_7': 'Bloom | Clinical | GAD-7 > x',
      'configuration.bloom.institution_configs.phq_9': 'Bloom | Clinical | PHQ-9 > x',
      'configuration.bloom.institution_configs.hurting_thoughts': 'Bloom | Clinical | Consider Hurting Thoughts',
      'configuration.bloom.toggles.show_vaginal_anatomy_question_on_onboarding': 'Bloom | Clinical | Show vaginal anatomy question on onboarding',
      'configuration.bloom.toggles.allow_no_pod_sessions': 'Bloom | Clinical | Allow no-pod sessions',
      'configuration.bloom.conditionsMapping': 'Bloom | Conditions',
      'configuration.bloom.onboarding_forms.injury_areas.bladder_health': 'Bloom | Conditions | Bladder health',
      // eslint-disable-next-line max-len
      'configuration.bloom.onboarding_forms.injury_areas.bladder_health.onboarding_complementary_form_key': 'Bloom | Conditions | Bladder health | Complementary form',
      // eslint-disable-next-line max-len
      'configuration.bloom.onboarding_forms.injury_areas.bladder_health.onboarding_regular_form_key': 'Bloom | Conditions | Bladder health | Main form',
      'configuration.bloom.onboarding_forms.injury_areas.bowel_health': 'Bloom | Conditions | Bowel health',
      // eslint-disable-next-line max-len
      'configuration.bloom.onboarding_forms.injury_areas.bowel_health.onboarding_complementary_form_key': 'Bloom | Conditions | Bowel health | Complementary form',
      'configuration.bloom.onboarding_forms.injury_areas.bowel_health.onboarding_regular_form_key': 'Bloom | Conditions | Bowel health | Main form',
      'configuration.bloom.onboarding_forms.injury_areas.chronic_pelvic_pain': 'Bloom | Conditions | Chronic pelvic pain',
      // eslint-disable-next-line max-len
      'configuration.bloom.onboarding_forms.injury_areas.chronic_pelvic_pain.onboarding_complementary_form_key': 'Bloom | Conditions | Chronic pelvic pain | Complementary form',
      // eslint-disable-next-line max-len
      'configuration.bloom.onboarding_forms.injury_areas.chronic_pelvic_pain.onboarding_regular_form_key': 'Bloom | Conditions | Chronic pelvic pain | Main form',
      'configuration.bloom.onboarding_forms.injury_areas.menopause': 'Bloom | Conditions | Menopause',
      // eslint-disable-next-line max-len
      'configuration.bloom.onboarding_forms.injury_areas.menopause.onboarding_complementary_form_key': 'Bloom | Conditions | Menopause | Complementary form',
      'configuration.bloom.onboarding_forms.injury_areas.menopause.onboarding_regular_form_key': 'Bloom | Conditions | Menopau | Main form',
      'configuration.bloom.onboarding_forms.injury_areas.pelvic_organ_prolapse': 'Bloom | Conditions | Pelvic organ prolapse',
      // eslint-disable-next-line max-len
      'configuration.bloom.onboarding_forms.injury_areas.pelvic_organ_prolapse.onboarding_complementary_form_key': 'Bloom | Conditions | Pelvic organ prolapse | Complementary form',
      // eslint-disable-next-line max-len
      'configuration.bloom.onboarding_forms.injury_areas.pelvic_organ_prolapse.onboarding_regular_form_key': 'Bloom | Conditions | Pelvic organ prolapse | Main form',
      'configuration.bloom.onboarding_forms.injury_areas.postpartum': 'Bloom | Conditions | Postpartum',
      // eslint-disable-next-line max-len
      'configuration.bloom.onboarding_forms.injury_areas.postpartum.onboarding_complementary_form_key': 'Bloom | Conditions | Postpartum | Complementary form',
      'configuration.bloom.onboarding_forms.injury_areas.postpartum.onboarding_regular_form_key': 'Bloom | Conditions | Postpartum | Main form',
      'configuration.bloom.onboarding_forms.injury_areas.pregnancy': 'Bloom | Conditions | Pregnancy',
      // eslint-disable-next-line max-len
      'configuration.bloom.onboarding_forms.injury_areas.pregnancy.onboarding_complementary_form_key': 'Bloom | Conditions | Pregnancy | Complementary form',
      'configuration.bloom.onboarding_forms.injury_areas.pregnancy.onboarding_regular_form_key': 'Bloom | Conditions | Pregnancy | Main form',
      'configuration.bloom.onboarding_forms.injury_areas.prevention': 'Bloom | Conditions | Prevention',
      // eslint-disable-next-line max-len
      'configuration.bloom.onboarding_forms.injury_areas.prevention.onboarding_complementary_form_key': 'Bloom | Conditions | Prevention | Complementary form',
      'configuration.bloom.onboarding_forms.injury_areas.prevention.onboarding_regular_form_key': 'Bloom | Conditions | Prevention | Main form',
      'configuration.bloom.onboarding_forms.injury_areas.sexual_health': 'Bloom | Conditions | Sexual health',
      // eslint-disable-next-line max-len
      'configuration.bloom.onboarding_forms.injury_areas.sexual_health.onboarding_complementary_form_key': 'Bloom | Conditions | Sexual health | Complementary form',
      'configuration.bloom.onboarding_forms.injury_areas.sexual_health.onboarding_regular_form_key': 'Bloom | Conditions | Sexual health | Main form',
      'configuration.move.enabled': 'Move | General | Is Move enabled',
      'configuration.move.support_email': 'Move | General | Support Email',
      'configuration.move.navigator_visible': 'Move | General | Show on navigator even if it is not available',
      'configuration.move.institution_configs.email_performances': 'Move | General | Email performances',
      'configuration.move.institution_configs.email_professional_after_session': 'Move | General | Email professional after session',
      'configuration.move.institution_configs.email_suggestions': 'Move | General | Email suggestions',
      'configuration.move.institution_configs.send_sms_reminders': 'Move | General | Send sms reminders',
      'configuration.move.toggles.allow_minor_enrollments': 'Move | General | Allow enrollments by minors',
      'configuration.move.capacity': 'Move | General | Capacity',
      'configuration.move.salesforce_account_id': 'Move | General | SalesForce program id',
      'configuration.move.technical_launch_date': 'Move | General | Technical launch date',
      'configuration.move.marketing_launch_date': 'Move | General | Marketing launch date',
      'configuration.move.unique_addressable_lives': 'Move | General | Unique Addressable Lives',
      'configuration.move.academy_enabled': 'Move | Member app | Academy enabled',
      'configuration.move.on_call_chat': 'Move | Member app | On call chat enabled',
      'configuration.move.show_on_navigator': 'Move | Member app | Show on navigator/hub',
      'configuration.move.cbt_program': 'Move | Member app | CBT Program',
      'configuration.no_program.enabled': 'No-program | Is no-program enabled',
      'configuration.no_program.academy_enabled': 'No-program | Academy enabled',
      'configuration.no_program.on_call_chat': 'No-program | On call chat enabled',
      'configuration.no_program.cbt_program': 'No-program | CBT Program',
      'configuration.no_program.salesforce_account_id': 'No-program | SalesForce program id',
      'configuration.triage_helper.enabled': 'Triage/Helper | Is triage helper enabled',
      'configuration.follows_care_ecosystem_rules': 'Ecosystem Vendors | Follows Standard Ecosystem Rules',
      'configuration.general_guidelines': 'Ecosystem Vendors | Client General Guidelines',
      'configuration.preferred_vendor': 'Ecosystem Vendors | Preferred Vendor for Referral',
      'configuration.toggles.can_create_order_client_test': 'General | Can create order even if client is in test mode',
      'configuration.toggles.order_management_disabled': 'General | Disable order management',
      'configuration.video_call_provider': 'Enrollment | Video call provider',
      statements_configurations: 'Statements Configurations',
      not_chargeable_health_plans: 'Fees & Conditions | Not chargeable Health Plans',
      with_cbt: 'CBT billing',
      with_ect: 'ECT billing',
      generate_claims_batches: 'Generate claims batches',
      clearing_house: 'Clearing house',
      generate_837_file: 'Generate 837 file',
      generate_external_claims_report: 'Generate external claims report',
      payer: 'Payer',
      billing_type: 'Billing type',
      'logo.current': 'Enrollment | Client logo',
    },
    entity_types: {
      client: 'Client',
      'finance-billing-model': 'Billing Configuration',
    },
    errors: {
      save: 'Error trying to save revision request',
      approve: 'Error trying to approve revision request',
      reject: 'Error trying to reject revision request',
      delete: 'Error trying to delete revision request',
      submit: 'Error trying to submit revision request for approval',
      draft: 'Error trying to save revision request as draft',
      publish: 'Error trying to publish revision request',
      addNotes: 'Error trying to add notes to revision request',
    },
    success: {
      save: 'Revision request saved successfully',
      approve: 'Revision request approved successfully',
      reject: 'Revision request rejected successfully',
      delete: 'Revision request deleted successfully',
      submit: 'Revision request submitted for approval successfully',
      draft: 'Revision request saved as draft successfully',
      publish: 'Revision request published successfully',
      addNotes: 'Notes added to revision request successfully',
      approveAndPublish: 'Revision request approved & published successfully',
    },
  },
  units: {
    bloom: 'Bloom',
    dpt: 'DPT',
    move: 'Move',
  },
  client_eligibility: {
    line_of_business: {
      section_title: 'Line of Business',
      modal_add_title: 'New Line of Business',
      modal_edit_title: 'Edit Line of Business',
      alert_text: 'Add Line of Business here for eligibility mapping',
      label_line_of_business_name: 'Line of Business Name',
      label_consortium_name: 'Consortium Name',
      label_consortium_id: 'Consortium ID',
      label_salesforce_account_id: 'Salesforce Account ID',
      placeholder_salesforce_account_id: 'SF Account ID',
      label_salesforce_program_id: 'Salesforce Program ID',
      placeholder_salesforce_program_id: 'SF Program ID',
      label_technical_launch_date: 'Technical Launch Date',
      label_marketing_launch_date: 'Marketing Launch Date',
      field_name: 'Name',
      field_consortium: 'Consortium',
      field_salesforce_id: 'SF account',
      field_salesforce_program_id: 'SF program',
      field_technical_launch_date: 'Tech launch date',
      field_marketing_launch_date: 'Marketing launch date',
      btn_new: 'New LOB',
      btn_save: 'Save',
      btn_delete: 'Delete',
      error_get_line_of_businesses: 'Error trying to get line of businesses',
      error_save_line_of_business: 'Error trying to save line of business',
      error_delete_line_of_business: 'Error trying to delete line of business',
      success_save_line_of_business: 'Line of business saved successfully',
      success_delete_line_of_business: 'Line of business deleted successfully',
    },
  },
};
